<template>
  <div class="mb-5 text-nowrap border-bottom overflow-auto">
    <router-link to="/manage-collection" class="btn btn-wz-tab">Manage Collection</router-link>
    <router-link to="/add-collection" class="btn btn-wz-tab">Add Collection</router-link>
    <router-link :to="$route.params.id ? '/edit-collection/' + $route.params.id : '#'" class="btn btn-wz-tab" :disabled="!$route.params.id">Edit Collection</router-link>
    <router-link :to="$route.params.id ? '/detail-collection/' + $route.params.id : '#'" class="btn btn-wz-tab" :disabled="!$route.params.id">Detail Collection</router-link>
  </div>
</template>
<script>
export default {
  name: "CollectionNavTab",
}
</script>
